<template>
  <section id="quotes-section" class="quotes-section">
    <h2 class="is-size-2 is-size-2-mobile">{{ quotes[person].headingPart1 }} <br /></h2>
    <div class="spacer p-4 is-hidden-mobile" />
    <div class="columns is-vcentered">
      <div class="column is-two-fifths has-text-centered">
        <img
          :src="quotes[person].imgSrc"
          :alt="$t('trustedQuote.alt.portrait') + quotes[person].author"
          class="face pr-4"
        />
      </div>
      <div class="column is-three-fifths">
        <p class="quote is-size-4 pb-4">
          {{ quotes[person].quote }}
        </p>
        <div class="spacer p-4 is-hidden-mobile pt-4" />
        <p class="author is-size-4 is-size-5-mobile">
          {{ quotes[person].author }}
        </p>
        <p class="authorCreds is-size-5 is-size-6-mobile">
          {{ quotes[person].authorCreds }}
        </p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "TrustedQuote",
  props: {
    person: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      felixImageSource: "/images/trust_quotes/trust_felix.webp",
      doronImageSource: "/images/trust_quotes/trust_doron.webp",
    }
  },
  computed: {
    quotes() {
      return {
        felix: {
          imgSrc: this.felixImageSource,
          headingPart1: this.$t("trustedQuote.felix.headingPart1"),
          headingPart2: this.$t("trustedQuote.felix.headingPart2"),
          quote: this.$t("trustedQuote.felix.quote"),
          author: this.$t("trustedQuote.felix.author"),
          authorCreds: this.$t("trustedQuote.felix.authorCreds"),
        },
        doron: {
          imgSrc: this.doronImageSource,
          headingPart1: this.$t("trustedQuote.doron.headingPart1"),
          quote: this.$t("trustedQuote.doron.quote"),
          author: this.$t("trustedQuote.doron.author"),
          authorCreds: this.$t("trustedQuote.doron.authorCreds"),
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quote {
  font-weight: 300;
  line-height: 1.4em;
  @media screen and (max-width: $tablet) {
    text-align: center;
  }
  margin-bottom: 8px;
}

.face {
  min-width: 320px;
}

.authorCreds {
  font-weight: 300;
  line-height: 1.4em;
  @media screen and (max-width: $tablet) {
    text-align: center;
  }
}
.author {
  font-weight: 500;
  line-height: 1.4em;
  @media screen and (max-width: $tablet) {
    text-align: center;
  }
}
.quotes-section {
  border-top: 2px solid rgba(29, 29, 29, 0.15);

  @media screen and (min-width: $tablet) {
    padding-top: 80px;
    padding-bottom: 96px;
    h2 {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: $tablet) {
    padding-top: 0;
    padding-bottom: 64px;

    .face {
      max-width: 80%;
      width: 50%;
    }
    h2 {
      text-align: center;
      padding-bottom: 56px;
      padding-top: 40px;
    }
  }

  @media screen and (max-width: $mobile) {
    padding-top: 0;
    padding-bottom: 64px;

    .face {
      width: 70%;
    }
    h2 {
      padding-bottom: 40px;
    }
  }
}
</style>
