<template>
  <div class="preview-header">
    <img :alt="$t('home.alt.logo')" src="~assets/images/memodio_logos/logo_icon_grey.svg" class="mb-3" />
    <h2 class="header-title is-size-2 is-size-2-mobile m-4 has-text-centered">
      {{ $t("home.weAre") }}
    </h2>
    <div class="is-size-5 has-text-centered">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-header {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -32px;

  @media screen and (max-width: $tablet) {
    margin-top: 40px;
  }
}
.header-title {
  font-weight: 600;

  @media screen and (max-width: $tablet) {
    margin-bottom: -24px;
  }
}
</style>
